<template>
  <help-page class="about-page" title="common.app-about">
    <el-card shadow="hover" class="tms-card">
      <div class="title">{{ $t('help.about-tms-title') }}</div>
      <hr class="split-line" />
      <div class="desc">
        {{ $t('help.about-tms-desc') }}
      </div>
    </el-card>
    <el-card shadow="hover">
      <div class="title">{{ $t('help.about-wisecard-title') }}</div>
      <el-collapse
        v-model="activeNames"
        v-for="(item, index) in solutionList"
        :key="index"
      >
        <el-collapse-item :title="$t(item.title)" :name="index">
          <div v-html="$t(item.desc)"></div>
        </el-collapse-item>
      </el-collapse>
    </el-card>
  </help-page>
</template>

<script setup>
import helpPage from '../help-page.vue'

const activeNames = [0, 1, 2]
const solutionList = [
  {
    title: 'help.solution-list-title-0',
    desc: 'help.solution-list-desc-0'
  },
  {
    title: 'help.solution-list-title-1',
    desc: 'help.solution-list-desc-1'
  },
  {
    title: 'help.solution-list-title-2',
    desc: 'help.solution-list-desc-2'
  }
]
</script>

<style scoped lang="scss">
.about-page {
  .split-line {
    margin-top: 20px;
    margin-bottom: 10px;
    color: #e9e9eb;
    background-color: #e9e9eb;
    height: 1px;
    border: none;
  }
  .title {
    font-weight: bold;
    font-size: 18px;
    margin-bottom: 18px;
  }
  :deep(.el-collapse-item__header) {
    font-weight: bold;
    font-size: 15px;
  }

  .tms-card {
    margin-bottom: 14px;
    .desc {
      font-size: 14px;
    }
  }
}
</style>
